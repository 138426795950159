<template>
    <div>
        <div class="table-responsive">
            <datatable
                :columns="columnsFormated"
                :data="itens"
                class="table"
            >
                <template slot="no-results">
                  <div class="text-center">
                    Nenhum resultado encontrado
                  </div>
                </template>
                <template slot-scope="{ row }">
                    <tr>
                        <td v-for="column in columnsFormated">
                            <template v-if="column.representedAs">
                                {{ column.representedAs(row) }}
                            </template>
                            <template v-else-if="column.field">
                                {{ row[column.field] }}
                            </template>
                            <template v-else>
                                <div class="text-right action-buttons">
                                    <slot
                                        name="action-buttons"
                                        :model="row"
                                    />
                                    <button
                                        v-if="onUpdate != null"
                                        type="button"
                                        class="button button-primary"
                                        v-on:click="onUpdate(row)"
                                    >
                                        <fa-icon icon="pencil-alt" />
                                    </button>
                                    <button
                                        v-if="onDelete != null"
                                        type="button"
                                        name="button"
                                        v-on:click="onDelete(row)"
                                        class="button button-error"
                                    >
                                        <fa-icon icon="trash-alt" />
                                    </button>
                                    <button
                                        v-if="onShow != null"
                                        type="button"
                                        name="button"
                                        v-on:click="onShow(row)"
                                        class="button button-info"
                                    >
                                        <fa-icon icon="info-circle" />
                                    </button>
                                </div>
                            </template>
                        </td>
                    </tr>
                </template>
            </datatable>
        </div>
        <pagination
            :data="paginator"
            :limit="2"
            @pagination-change-page="onPageChange"
        />
    </div>
</template>

<script>
  import clone from 'just-clone';

  export default {
    name: "TablePaginated",
    props: [
      'columns',
      'itens',
      'paginator',
      'onDelete',
      'onUpdate',
      'onShow',
      'onPageChange'
    ],
    computed: {
      columnsFormated() {
        let rawColumns = clone(this.columns);

        if (this.onDelete || this.onUpdate || this.onShow) {
          rawColumns.push({ label: '#', sortable: false, })
        }

        return rawColumns;
      }
    },
  }
</script>

<style scoped>
  .action-buttons * {
    margin-left: 4px;
  }
</style>